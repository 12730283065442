import * as React from "preact";
import { JSX } from "preact";

export interface IconContext {
  color?: string;
  size?: string;
  class?: string;
  stroke?: string | JSX.SignalLike<string | undefined>; // allow overide stroke color "currentColor"
  fill?: string | JSX.SignalLike<string | undefined>; // allow overide fill color "currentColor"
  strokeWidth?:  number | string | JSX.SignalLike<number | string | undefined>; // allow overide strokeWidth default 0

  style?: JSX.CSSProperties;
  attr?: JSX.SVGAttributes<SVGSVGElement>;
}

export const defaultContext: IconContext = {
  color: undefined,
  size: undefined,
  class: undefined,
  style: undefined,
  attr: undefined,
};

export const defaultIconContext: React.Context<IconContext> = React.createContext && React.createContext(defaultContext);

// denoCacheMetadata={"headers":{"strict-transport-security":"max-age=31536000; includeSubDomains; preload","access-control-allow-origin":"*","alt-svc":"h3=\":443\"; ma=86400","accept-ranges":"bytes","cross-origin-resource-policy":"cross-origin","x-served-by":"cache-fra-etou8220096-FRA, cache-lga21966-LGA","etag":"W/\"32a-Bmotoc1mE42JAxp+yFUNnwalsPg\"","cache-control":"public, max-age=31536000, s-maxage=31536000, immutable","x-cache":"HIT, HIT","x-content-type-options":"nosniff","report-to":"{\"endpoints\":[{\"url\":\"https:\\/\\/a.nel.cloudflare.com\\/report\\/v4?s=U7ZKLytX%2FEKYViEHhPYxJXaRHHDcZwoSc%2BpBupWDt%2FJdpPbWZjDgMaR67BAdYhfTF8I61ef0nNpUXnCfNWs4GfJvFBePsz0Jho77SCUwE%2FMDnQA23JfE6Jdy51RvHlIRm9fYDyi7wldhA5ixH%2FE%3D\"}],\"group\":\"cf-nel\",\"max_age\":604800}","access-control-expose-headers":"*","nel":"{\"success_fraction\":0.01,\"report_to\":\"cf-nel\",\"max_age\":604800}","vary":"Accept-Encoding","cf-cache-status":"HIT","content-type":"text/plain; charset=utf-8","timing-allow-origin":"*","x-jsd-version":"1.0.8","cf-ray":"8eec823bbf16e928-DME","x-jsd-version-type":"version","server":"cloudflare","date":"Sun, 08 Dec 2024 11:45:21 GMT"},"url":"https://cdn.jsdelivr.net/gh/urielch/react-icons@1.0.8/lib/iconContext.ts","time":1733658321}